import { update } from 'reduxigen'
import moment from 'moment'

import { request } from 'tools'
import * as api from './api'

export const clearAuthError = update(
    'storage.auth.flush',
    ( v, state ) => ({
        ...state,
        auth: {
            ...state.auth,
            error: ''
        }
    })
)

export const saveToken = request.updateAndSave(
    'storage.auth.token',
    ( token, state ) => ({
        ...state,
        auth: {
            ...state.auth,
            token
        }
    })
)

export const auth = request.requestAndSave(
    'storage.auth.try',
    params => api.auth( params ),
    ( event, state ) => {
        const
            status = event.status === 200
        
        let
            msg = ''

        if ( !status ) {
            switch ( event.data.code ) {
                case 'auth.failed':
                    msg = 'Ошибка авторизации'
                    break
                case 'Network Error':
                    msg = 'Ошибка сети'
                    break
                default:
                    msg = 'Неизвестная ошибка'
            }
        }

        return {
            ...state,
            auth: {
                ...state.auth,
                token: event.data.access_token,
                refresh: event.data.refresh_token,
                logged: status,
                error: msg,
                lastTry: moment()
            }
        }
    }
)