import React from 'react'
import { generate } from 'tools'
import t from 'tools/translate'

const scrolls =
    {
        renderTrackVertical:   props => <div {...props} className="v-scroll-track" />,
        renderThumbVertical:   props => <div {...props} className="v-scroll-thumb" />,
        renderTrackHorizontal: props => <div {...props} className="h-scroll-track" />,
        renderThumbHorizontal: props => <div {...props} className="h-scroll-thumb" />,
        hideTracksWhenNotNeeded: true
    }

const dates = {
    def: 'YYYY-MM-DDTHH:mm:ss',
    classic: 'DD.MM.YYYY',
    nice: 'D MMMM, YYYY',
    time: 'HH:mm'
}

const
    apiUrl = process.env.REACT_APP_BASE

const config = () =>
    Object.freeze( Object.assign( {}, {
        version: '1.1.3',
        build: '2021-06-15',

        api: {
            url: apiUrl
        },

        scrolls,

        ui: {
            dates,
            stub: count => ( new Array( count || 4 ).fill( 0 ).map( ( z, i ) => ({ id: i }) ) ),
            notifications: {
                getContainer: () => document.querySelector( '.page-content' ),
                duration: 1
            },
            themes: {
                'antd': t('settings.light'), 
                'verve': t('settings.dark')
            },
            themesSelect: {
                'antd': t('settings.enableDark'),
                'verve': t('settings.enableLight')
            },
            colorModesIndex: [
                'none',
                'tiny',
                'full',
                'adaptive'
            ],
            colorModes: {
                'none': t('settings.noColor'),
                'tiny': t('settings.bordered'),
                'full': t('settings.fill'),
                'adaptive': t('settings.positionBased')
            },
            canShowInHead: {
                'zoom': t('settings.font'),
                'colorMode': t('settings.color'),
                'theme': t('settings.theme'),
                'language': t('settings.language')
            }
        },

        zoom: [
            '.583',
            '.75',
            '.916',
            '1',
            '1.16',
            '1.25',
            '1.5'
        ],

        def: {
            settings: {
                language: 'en',
                showInHead: [ 'theme', 'language' ],
                colorMode: 'tiny',
                zoom: '1',
                theme: window.matchMedia('(prefers-color-scheme:dark)').matches ? 'verve' : 'antd',
                removeImmediately: false,
                filters: [],
                keys: [ 
                    'Not urgent', 
                    'Urgent',
                    'Not important', 
                    'Important'
                ]
            },
            events: [{
                id: generate.uniq(),
                title: t( 'first.title' ),
                urgency: .12,
                importance: .3,
                content: t( 'first.description' ),
                tags: [ t( 'help' ) ]
            }],
            draft: {
                _new: true,
                id: null,
                title: '',
                urgency: 0,
                importance: 0,
                content: '',
                tags: []
            }
        }
    }))

export default config