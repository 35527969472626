/* VENDOR */
import React, { Component } from 'react'
import { Alert, Input, Button } from 'antd'
import Icon from '@ant-design/icons'

/* APPLICATION */
import { Center, Icons } from 'components'
import { generate, strings } from 'tools'

import connector from './connector'
import './login.scss'

class Login extends Component {

    constructor ( props ) {
        super( props )

        this.state = {
            username: '',
            pwd: '',
            mail: '',
            
            sent: false,
            restore: false,
            progress: false,
            mailError: false
        }

        this.set = generate.set( this )
    }

    componentDidMount () {
        const
            { token } = this.props

        ;( token ) && ( this.props.authTest() )
    }

    componentDidUpdate ( prevProps ) {
        const
            { lastTry, logged, token } = this.props

        ;( !lastTry.isSame( prevProps.lastTry ) ) && ( this.set.progress( false ) )
        ;( !!logged ) && ( this.props.history.push( '/' ) )
        ;( token ) && ( this.props.authTest() )
    }

    update = key => e => this.set[key]( e.target.value )

    restore = () =>
        {
            this.props.clearAuthError() 
            this.set.restore( true )
        }

    back = () => 
        this.set.state({
            mailError: false,
            restore: false,
            mail: ''
        })

    auth = () =>
        {
            const
                { username, pwd } = this.state
                
            this.set.progress( true )
            this.props.clearAuthError()
            this.props.auth({ 
                grant_type: 'password',
                client_id: 'admin-panel',
                username,
                password: pwd
            })
        }

    sendRestore = () =>
        {
            this.set.mailError( false )

            if ( strings.validateEmail( this.state.mail ) ) {
                this.set.sent( true )
            } else {
                this.set.mailError( true )
            }
        }

    restoreForm = () =>
        {
            const
                { mail, sent, mailError } = this.state

            return (
                <div className="login-form">
                    {
                        sent
                            ? (
                                <React.Fragment>
                                    <h4>Сообщение успешно отправлено</h4>
                                    <p>Перейдите по ссылке в письме и укажите новый пароль</p>
                                </React.Fragment>
                            )
                            : (
                                <React.Fragment>
                                    <h4>Введите почту привязанную к аккаунту</h4>
                                    <p>На указанную почту придет письмо с ссылкой для восстановления пароля</p>
                                    <Input
                                        placeholder = "Почта"
                                        value       = { mail }
                                        error       = { mailError }

                                        onChange  = { this.update( 'mail' ) }
                                    />
                                    <Button
                                        type     = "primary"
                                        disabled = { !mail }

                                        onClick = { this.sendRestore }
                                    >
                                        Отправить
                                    </Button>
                                </React.Fragment>
                            )
                    }
                    <span className="link" onClick={this.back}>Вернуться назад</span>
                </div>
            )
        }

    loginForm = () =>
        {
            const
                { username, pwd, progress } = this.state,
                { error } = this.props

            return (
                <div className="login-form">
                    <Input
                        placeholder = "Логин"
                        value       = { username }
                        disabled    = { progress }

                        onChange  = { this.update( 'username' ) }
                    />
                    <Input
                        type        = "password"
                        placeholder = "Пароль"
                        value       = { pwd }
                        disabled    = { progress }

                        onChange  = { this.update( 'pwd' ) }
                    />
                    <span className="link to-left" onClick={this.restore}>Забыли пароль?</span>
                    <Button
                        type     = "primary"
                        disabled = { progress || !username || !pwd }

                        onClick = { this.auth }
                    >
                        Войти
                    </Button>
                    {
                        !!error && (
                            <Alert
                                showIcon
                                icon    = {<Icon component={() => Icons.exclaim} />}
                                message = { error }
                                type    = "error"
                            />
                        )
                    }
                </div>
            )
        }

    render () {
        const
            { restore } = this.state

        return (
            <section className="app-page app-page-login">
                <Center>
                    <div className="login-form-container">
                        <header className="login-header">
                        </header>
                        {
                            restore
                                ? this.restoreForm()
                                : this.loginForm()
                        }
                    </div>
                </Center>
            </section>
        )
    }
}

export default connector( Login )