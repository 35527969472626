import * as copy from './copy'

export const set = cmp =>
    {
        const 
            res = {},
            keys = Object.keys( cmp.state )

        keys.forEach( key => {
            res[ key ] = ( val, cb ) => cmp.setState( { [key]: val }, typeof cb === 'function' ? cb : void 0 )
        })

        res.state = ( data, cb ) => cmp.setState( data, typeof cb === 'function' ? cb : void 0 )

        res.delay = ( key, cb ) => value => () => cmp.setState({ [key]: value }, cb )
        res.toggle = ( key, cb ) => () => cmp.setState({ [key]: !cmp.state[key] }, cb )

        res.byChecked = ( key, cb ) => e => 
            cmp.setState(
                { [key]: e.target.checked }, 
                cb 
            )

        res.byValue = ( key, cb ) => e =>
            cmp.setState(
                { [key]: e.target.value },
                cb
            )

        return res
    }

export const addState = ( cmp, add ) =>
    {
        cmp.state = cmp.state
            ? { ...cmp.state, ...add }
            : add

        cmp.set = set( cmp )
    }

export const range = ( min, max, base ) =>
    {
        const
            zero = base || 0,
            res = []

        for ( let i = zero + min; i <= zero + max; i++ ) {
            res.push( i )
        }

        return res
    }

export const update = ( cmp, field, rawPrep, after ) => 
    ( key, type, source ) => e => 
        {
            const
                res = copy.deep( cmp.state[field] ),
                prepare = rawPrep || ( t => t )

            switch ( type ) {
                case 'checked':
                    res[key] = prepare( e.target.checked )
                    break
                case 'value':
                    res[key] = prepare( e.target.value )
                    break
                case 'array':
                    res[key] = prepare( source[e] )
                    break
                case 'key':
                    res[key][source] = prepare( e )
                    break
                default:
                    res[key] = prepare( e )
            }

            cmp.set[field]( res )

            ;( after ) && ( after( key, e ) )
        }

export const uniq = () => '_' + Math.random().toString(36).substr(2, 9)