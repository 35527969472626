/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Pagination as AntPagination, Select, Skeleton } from 'antd'
import { VerticalRightOutlined, VerticalLeftOutlined } from '@ant-design/icons'

/* APPLICATION */
import './pagination.scss'

class Pagination extends Component {

    static propTypes = {
        data:  PropTypes.array,
        page:  PropTypes.number,
        count: PropTypes.number,
        total: PropTypes.number,

        onPage:  PropTypes.func.isRequired,
        onCount: PropTypes.func.isRequired
    }

    static defaultProps = {
        count: 10,
        page: 1
    }

    options = [
        {
            label: '1',
            value: 1
        },
        {
            label: '2',
            value: 2
        },
        {
            label: '10',
            value: 10
        },
        {
            label: '20',
            value: 20
        },
        {
            label: '50',
            value: 50
        },
        {
            label: '100',
            value: 100
        }
    ]

    updateCount = count => this.props.onCount( count )

    goToStart = () => this.props.onPage( 1 )
    goToEnd = () => this.props.onPage( Math.ceil( this.props.total / this.props.count ) )

    first = () =>
        {
            const
                { page } = this.props,
                cls = [ 'ant-pagination-prev', 'ant-pagination-prev-max' ]

            ;( page === 1 ) && ( cls.push( 'ant-pagination-disabled' ) )

            return (
                <ul className="ant-pagination ant-pagination-add">
                    <li className={cls.join( ' ' )}>
                        <button 
                            title = "Первая страница"
                            className = "ant-pagination-item-link"
                            onClick = { this.goToStart }
                        >
                            <VerticalRightOutlined />
                        </button>
                    </li>
                </ul>
            )
        }

    last = () =>
        {
            const
                { data, page, count } = this.props,
                total = data ? Math.ceil( this.props.total / count ) : 0, 
                cls = [ 'ant-pagination-next', 'ant-pagination-next-max' ]

            ;( page === total ) && ( cls.push( 'ant-pagination-disabled' ) )

            return (
                <ul className="ant-pagination ant-pagination-add">
                    <li className={cls.join( ' ' )}>
                        <button 
                            title = "Последняя страница"
                            className = "ant-pagination-item-link"
                            onClick = { this.goToEnd }
                        >
                            <VerticalLeftOutlined />
                        </button>
                    </li>
                </ul>
            )
        }

    render () {
        const
            { data, count, page } = this.props,
            total = data ? this.props.total : 0

        if ( !data ) return (
            <div className="app-pagination">
                <Skeleton.Input active style={{ width: 200, height: 32 }} />
            </div>
        )

        return (
            <div className="app-pagination">

                <span className="label">Строк на странице:</span>
                <Select value={count} options={this.options} onChange={this.updateCount} />

                <span className="current-total">{ (page - 1) * count + 1 }–{ Math.min( page * count, total ) } из { total }</span>

                { this.first() }

                <AntPagination  
                    showSizeChanger={false} 

                    current  = { page } 
                    total    = { total }
                    pageSize = { count }

                    locale = {{
                        prev_page: 'Предыдущая страница',
                        next_page: 'Следующая страница'
                    }}
                    
                    onChange = { this.props.onPage }
                />

                { this.last() }
            </div>
        )
    }
}

export default Pagination
