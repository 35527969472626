/* VENDOR */
import React, { Component } from 'react'
import { Skeleton } from 'antd'

/* APPLICATION */
import { Icons, Center } from 'components'
import { generate } from 'tools'

class BrokenImage extends Component {

    image = null

    constructor ( props ) {
        super( props ) 

        this.state = {
            error: null
        }

        this.set = generate.set( this )
    }
    
    componentDidMount () {
        this.load()
    }

    componentDidUpdate ( prevProps ) {
        if ( this.props.src !== prevProps.src ) {
            this.set.error( null )
            this.load()
        }
    }

    componentWillUnmount () {
        this.image.onerror = null
        this.image.onload = null
        this.image = null
    }

    load = () =>
        {
            const
                { src } = this.props

            this.image = new Image()

            this.image.onerror = this.error
            this.image.onload = this.success

            this.image.src = src
        }

    error   = () => this.set.error( true )
    success = () => this.set.error( false )
    
    render () {
        const
            { error } = this.state

        return (
            error === true
                ? <Skeleton.Image />
                : (
                    error === null
                        ? (
                            <div className="ant-skeleton">
                                <div className="ant-skeleton-image no-bg">
                                    <Center>
                                        { Icons.spinner }
                                    </Center>
                                </div>
                            </div>
                        )
                        //eslint-disable-next-line
                        : <img {...this.props} onLoad={this.success} onError={this.error} />
                )
        )
    }
}

export default BrokenImage
