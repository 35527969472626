import axios from 'axios'
import { asyncUpdate as _asyncUpdate, update as _update } from 'reduxigen'

import config from 'config'

const 
    { CancelToken } = axios,
    cancels = {}

export const fix = ({ count, page, ...params }) =>
    {
        const
            res = {
                ...params
            }

        ;( count ) && ( res.size = count )
        ;( page ) && ( res.page = page - 1 )

        return res
    }

export const options = () =>
    ({
        validateStatus: null,
        headers: {
            Authorization: `Bearer ${localStorage.getItem( `${config().ui.prefix}_access_token` )}`
        }
    })

const cancel = name =>
    ({
        cancelToken: new CancelToken( function executor ( token ) { cancels[name] = token } )
    })

export const get = ( endpoint, params, uniq ) =>
    {
        const add = uniq || ''

        cancels[endpoint + add] && cancels[endpoint + add]()

        return axios.get(
            config().api.url + endpoint, 
            {
                params,
                ...options(),
                ...cancel( endpoint + add )
            }
        )
    }

export const remove = ( endpoint, cfg ) => axios.delete(
    config().api.url + endpoint,
    {
        ...options(),
        ...cfg
    }
)

export const post = ( endpoint, body, cfg ) => axios.post(
        config().api.url + endpoint,
        body,
        {
            ...options(),
            ...cfg
        }
    )

export const patch = ( endpoint, body, cfg ) => axios.patch(
        config().api.url + endpoint,
        body,
        {
            ...options(),
            ...cfg
        }
    )

const saveOne = ( key, value ) => localStorage.setItem( key.replace( 'storage', config().ui.prefix ).replace( /\./g, '_' ), value )

const savior = ( key, handler ) => ( value, state ) => 
    {
        if ( typeof value === 'object' ) {
            if ( value.status && value.data ) {
                Object.keys( value.data ).forEach( sub => {
                    saveOne( 'storage.' + sub, value.data[sub] )
                })
            }
        } else {
            saveOne( key, value )
        }

        return handler( value, state )
    }

export const update = ( name, req, parser ) => _update( name, req, parser )

export const asyncUpdate = ( name, requester, parser ) =>
    {
        return _asyncUpdate(
            name,
            params => 
                new Promise( 
                    resolve =>
                        requester( params )
                            .then( event => resolve( event ) )
                            .catch( 
                                event => {
                                    if ( axios.isCancel( event ) ) return

                                    return (
                                        resolve( 
                                            typeof event === 'object' && event.status
                                                ? event
                                                : {
                                                    data: {
                                                        ...event,
                                                        error: true,
                                                        data: event.data || null,
                                                        code: event.message,
                                                        message: event.toJSON()
                                                    }
                                                }
                                        )
                                    )
                                }
                            )
                ),
            ( event, state ) => {
                const
                    nstate = {
                        ...state,
                        networkError: false
                    }
                    
                if ( event.status === 401 ) {
                    window.location = '/login'
                    return
                }
                
                if ( event.data.error ) {
                    nstate.networkError = {
                        status: event.status
                    }
                }

                return parser( event, nstate )
            }
        )
    }

export const requestAndSave = ( key, req, handler ) => asyncUpdate(
    key,
    req,
    savior( key, handler )
)

export const updateAndSave = ( key, handler ) => _update(
    key,
    savior( key, handler )
)