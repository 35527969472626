export default class Get {
    constructor ( cmp ) {
        this.cmp = cmp
    }
    
    matrix = () => 
        [ [ this.cmp.NM, this.cmp.UM ], [ this.cmp.NI, this.cmp.UI ] ]

    fitToGrid = data =>
        {
            const
                gridX = window.innerWidth / 32,
                gridY = window.innerHeight / 64

            data.urgency = Math.round( data.urgency * gridX ) / gridX
            data.importance = Math.round( data.importance * gridY ) / gridY

            return data
        }

    place = point =>
        {
            let
                urgency = 0,
                importance = 0

            if ( this.cmp.container && this.cmp.container.current && point ) {
                const
                    { off } = this.cmp.state,
                    { w, h } = this.offsets()
                    
                urgency = Math.max( -.9, Math.min( .9, ( point.pageX - off.x - w ) / w ) )

                importance = Math.max( -.9, Math.min( .9, ( h * 2 - point.pageY + off.y - h ) / h ) )
            }

            return this.fitToGrid({ urgency, importance })
        }

    position = data =>
        {
            const
                { w, h, o } = this.offsets()

            return { x: Math.floor( w + data.urgency * w ), y: Math.floor( h + data.importance * -h - o ) }
        }

    offsets = () =>
        {
            if ( !this.cmp.container || !this.cmp.container.current ) {
                return {
                    w: window.innerWidth / 2,
                    h: window.innerHeight / 2,
                    o: 0
                }
            }

            const
                w = this.cmp.container.current.offsetWidth / 2,
                h = this.cmp.container.current.offsetHeight / 2,
                o = 0//document.querySelector( '.app-head' ).offsetHeight

            return { w, h, o }
        }
}