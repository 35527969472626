/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Input, Select, Button } from 'antd'

/* APPLICATION */
import { generate, storage, compare, t } from 'tools'
import './edit-event.scss'

class EditEvent extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        data: PropTypes.object,

        onSave:  PropTypes.func,
        onClose: PropTypes.func
    }

    title = React.createRef()
    text  = React.createRef()
    tags  = React.createRef()

    constructor ( props ) {
        super( props )

        this.state = {
            data: props.data ? { ...props.data } : props.data
        }

        this.set = generate.set( this )
    }

    componentDidUpdate ( prevProps ) {
        const
            { data, visible, focus } = this.props

        ;( data !== prevProps.data || ( visible !== prevProps.visible && visible ) ) && ( this.set.data({ ...data }) )
        ;( visible !== prevProps.visible && visible ) && ( setTimeout( () => this[focus || 'title'].current.focus(), 200 ) )
    }

    save = () => this.props.onSave( this.state.data )

    update = generate.update( this, 'data' )
    updateArr = generate.update( 
        this, 
        'data', 
        tags => tags.map( tag => tag.toLowerCase() ),
        //() => document.querySelector( 'button' ).focus()
    )

    next = ref => e =>
        {
            if ( e.ctrlKey ) {
                ;( !this.disabled() ) && ( this.save() )
            } else {
                if ( ref && ref.current ) {
                    setTimeout( () => ref.current.focus(), 0 )
                }
            }
        }

    disabled = () =>
        {
            const
                { data } = this.props,
                changed = data && this.state.data && !compare.objects( data, this.state.data, [ '_new' ] )

            return !data || !changed || !this.state.data.title
        }

    listTags = () =>
        {
            const
                tags = storage.extract( 'events', 'tags' )

            if ( tags.length < 1 ) {
                return <Select.Option key="none" disabled>{ t('tags.none') }</Select.Option>
            }

            return tags.map( tag => tag.toLowerCase() ).map( tag => (
                <Select.Option key={tag}>
                    { tag }
                </Select.Option>
            ))
        }

    content = () =>
        {
            const
                { data } = this.state

            if ( !data ) return null

            return (
                <React.Fragment>
                    <Input
                        ref = { this.title }

                        value = { data.title }
                        placeholder = { t('event.titlePlaceholder') }

                        onChange = { this.update( 'title', 'value' ) }
                        onPressEnter = { this.next( this.text ) }
                    />
                    <Input.TextArea
                        ref = { this.text }

                        value = { data.content }
                        placeholder = { t('event.descriptionPlaceholder') }
                        rows  = { 5 }

                        onChange = { this.update( 'content', 'value' ) }
                        onPressEnter = { this.next() }
                    />
                    <Select 
                        ref = { this.tags }
                        
                        mode  = "tags" 
                        value = { data.tags }

                        placeholder     = { t('event.tagsPlaceholder') }
                        style           = {{ width: '100%' }} 
                        tokenSeparators = {[',']}

                        onChange       = { this.updateArr( 'tags' ) }
                    >
                        { this.listTags() }
                    </Select>
                </React.Fragment>
            )
        }

    render () {
        const
            { visible, data } = this.props,
            disabled = this.disabled(),
            content = this.content()

        return (
            <Modal
                title   = { data && data._new ? t('event.add') : t('event.edit') }
                visible = { visible }

                okText = { t('global.save') }
                okButtonProps = {{ disabled }}
                onOk = { this.save }

                cancelText = { t('global.cancel') }
                onCancel = { this.props.onClose }
            >
                { content }
                <div className="modal-subfoot">
                    {
                        ( data && !data._new ) && (
                            <Button
                                type = "link"
                                danger
                                
                                onClick = { this.props.onRemove }
                            >
                                { t('global.remove') }
                            </Button>
                        )
                    }
                </div>
            </Modal>
        )
    }
}

export default EditEvent
