/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal, Radio, Row, Col, Input, Checkbox, Select, Tabs, Button } from 'antd'
import Icon, { SwapOutlined } from '@ant-design/icons'

/* APPLICATION */
import { Icons } from 'components'
import { copy, t, i18next } from 'tools'
import config from 'config'

import './settings.scss'

class Settings extends Component {

    saveTimer = null

    static propTypes = {
        visible:  PropTypes.bool,
        settings: PropTypes.object,

        onChange: PropTypes.func,
        onFlush:  PropTypes.func,
        onClose:  PropTypes.func
    }

    change = ( key, type, source ) => e => this.props.onChange( key, type, source )( e.target.value )

    swap = ( a, b ) => e =>
        {
            e.preventDefault()
            
            const
                keys = copy.array( this.props.settings.keys ),
                tmp = keys[a]

            keys[a] = keys[b]
            keys[b] = tmp

            this.props.onChange( 'keys' )( keys )
        }

    setLang = lang => 
        {
            i18next.changeLanguage( lang )
            this.props.onChange( 'language' )( lang )
        }

    fastHead = () => 
        Object.keys( config().ui.canShowInHead ).map( check => (
            <Checkbox key={check} value={check} checked={this.props.settings.showInHead.includes( check )}>
                { config().ui.canShowInHead[check] }
            </Checkbox>
        ))

    colorModes = () =>
        Object.keys( config().ui.colorModes ).map( ( mode, index ) => (
            <Radio.Button key={mode} value={mode}>
                { 
                    index === 3 || index === 0
                        ? config().ui.colorModes[mode]
                        : config().ui.colorModes[mode].split( ' ' )[0]
                }
            </Radio.Button>
        ))

    zoom = () =>
        config().zoom.map( ( z, index ) => (
            <Radio.Button key={z} value={z}>
                <span style={{ fontSize: `${z}em` }}>A</span>
            </Radio.Button>
        ))

    themes = () =>
        Object.keys( config().ui.themes ).map( theme => (
            <Radio.Button key={theme} value={theme}>
                { config().ui.themes[theme] }
            </Radio.Button>
        ))

    languages = () =>
        Object.keys( i18next.options.resources ).map( key => (
            <Select.Option key={key} value={key}>
                { i18next.options.resources[key].main.global.language }
            </Select.Option>
        ))

    render () {
        const
            { visible, settings } = this.props

        return (
            <Modal
                title = { t('settings.title') }
                visible = { visible }
                className = "settings-modal"

                okText = { t('global.save') }
                okButtonProps = {{ style: { display: 'none' }}}

                cancelText = { t('global.cancel') }
                cancelButtonProps = {{ style: { display: 'none' }}}
                onCancel = { this.props.onClose }
            >
                <Tabs>

                    <Tabs.TabPane tab={t('settings.general')} key="general">
                        <Checkbox 
                            checked = { settings.removeImmediately }
                            style   = {{ marginTop: 10 }}

                            onChange = { this.props.onChange( 'removeImmediately', 'checked' ) }
                        >
                            { t('settings.removeImmediately') }
                        </Checkbox>

                        <label>{ t('settings.fastHead') }</label>
                        <Checkbox.Group onChange={this.props.onChange( 'showInHead' )} value={settings.showInHead}>
                            { this.fastHead() }
                        </Checkbox.Group>

                        <label>{ t('settings.language') }</label>
                        <div className="inline-row">
                            <Icon component={Icons.Lang} />
                            <Select onChange={this.setLang} value={settings.language}>
                                { this.languages() }
                            </Select>
                        </div>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={t('settings.appearance')} key="appearance">
                        <label>{ t('settings.color') }</label>
                        <Radio.Group onChange={this.props.onChange( 'colorMode', 'value' )} value={settings.colorMode}>
                            { this.colorModes() }
                        </Radio.Group>

                        <label>{ t('settings.fontSize') }</label>
                        <Radio.Group onChange={this.props.onChange( 'zoom', 'value' )} value={settings.zoom}>
                            { this.zoom() }
                        </Radio.Group>

                        <label>{ t('settings.theme') }</label>
                        <Radio.Group onChange={this.props.onChange( 'theme', 'value' )} value={settings.theme}>
                            { this.themes() }
                        </Radio.Group>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab={t('settings.customization')} key="customization">
                        <label>{ t('settings.fields') }</label>
                        <Row>
                            <Col span={11}>
                                <Input
                                    value = { t( settings.keys[0] ) }
                                    onChange = { this.change( 'keys', 'key', 0 ) }
                                />
                            </Col>
                            <Col span={2} style={{ textAlign: 'center' }}>
                                <SwapOutlined onClick={ this.swap( 0, 1 )} />
                            </Col>
                            <Col span={11}>
                                <Input
                                    value = { t( settings.keys[1] ) }
                                    onChange = { this.change( 'keys', 'key', 1 ) }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={11}>
                                <Input
                                    value = { t( settings.keys[2] ) }
                                    onChange = { this.change( 'keys', 'key', 2 ) }
                                />
                            </Col>
                            <Col span={2} style={{ textAlign: 'center' }}>
                                <SwapOutlined onClick={ this.swap( 2, 3 )} />
                            </Col>
                            <Col span={11}>
                                <Input
                                    value = { t( settings.keys[3] ) }
                                    onChange = { this.change( 'keys', 'key', 3 ) }
                                />
                            </Col>
                        </Row>
                        <Button ghost onClick={this.props.onFlush}>
                            { t('global.reset') }
                        </Button>
                    </Tabs.TabPane>
                </Tabs>
                <hr/>
                <small>
                    <b>Eisenatrix</b>, { t('settings.version') } { config().version } ({ config().build })
                    <br/>
                    {/* eslint-disable-next-line */}
                    &copy; <a target="_blank" href="https://markushev.pro">markushev.pro</a>
                    <br/>
                    { t('settings.translators') }: Carolina Valencia (Spanish); Zhamila Nildibaeva (Japanese)
                </small>
            </Modal>
        )
    }
}

export default Settings