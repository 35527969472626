import React, { Component } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/ru'

import { Matrix } from 'pages'

import connector from './appConnector'
import './app.scss'

/* THEMES */
import './themes/antd'
import './themes/verve'

moment.locale( 'ru', {
    week: {
        dow: 1,
    }
})

class App extends Component {
    render () {
        return (
            <div className="App">
                <Switch>
                    <Route exact path='/' component={Matrix} />
                </Switch>
            </div>
        )
    }
}

export default connector( withRouter( App ) )
