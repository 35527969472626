/* IMPORT */
import { connect } from 'react-redux'
import { authActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    tested:  state.auth.tested,
    logged:  state.auth.logged,
    token:   state.auth.token,
    lastTry: state.auth.lastTry,
    error:   state.auth.error
})

const allActions = {
    ...authActions
}

export default connect( mapStateToProps, allActions )