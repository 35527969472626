/* IMPORT */
import { connect } from 'react-redux'
import { authActions } from 'services'

/* EXPORT */

const mapStateToProps = state => ({
    logged: state.auth.logged
})

const allActions = {
    ...authActions
}

export default connect( mapStateToProps, allActions )