import './antd.scss'

window.themes = window.themes || {}

window.themes.antd = {
    getBGColor: data =>
        {
            const
                // def color
                c = 238,
                u = data.urgency,
                i = data.importance,
                r = Math.round( c - Math.max( 0, ( c - 197 ) * u )  - Math.max( 0, ( c - 132 ) * i ) + Math.max( 0, 22 * ( u - i ) ) + 106 * Math.max(0,(u + i)/2) - 90 * ( 1 - Math.abs( i ) ) * Math.abs( Math.min( 0, u ) ) ),
                g = Math.round( c - Math.max(0,( c - 44 ) * u) - (( c - 198) * ((i + 1)/2)) + Math.max( 0, 63 * ( u - i ) ) + 40 * Math.max(0,(u + i)/2) ),
                b = Math.round( c - ( c - 99 ) * ( ( i + 1 ) / 2 ) - ( c - 74 ) * ( ( u + 1 ) / 2 ) + 93 * Math.max( 0, ( u + i ) / 2 ) )

            return { r: Math.min( 255, r ), g, b }
        },
    getColor: data =>
        {
            const
                bg = window.themes.antd.getBGColor( data ),
                c = 255 - ( 
                    Math.round( Math.sqrt( 
                        0.108 * Math.pow( bg.r, 2 ) + 
                        0.372 * Math.pow( bg.g, 2 ) + 
                        0.233 * Math.pow( bg.b, 2 )
                    ) / 255 ) * 255
                )

            return { r:c, b:c, g:c }
        }
}