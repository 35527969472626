export const count = ( raw, variants, formatter ) =>
    {
        const
            count = raw === void 0 ? 0 : raw,
            f = formatter || ( t => t ),
            v = !variants || f( count ) === count ? variants : variants[2]
            
        return (
            variants
                ? ( 
                    count === 0 
                        ? 'Нет ' + ending( 0, v )
                        : f( count ) + ' ' + ending( count, v )
                ) 
                : f( count )
        )
    }

export const ending = ( num, variants ) =>
    {
        if ( !variants ) return ''

        if ( typeof variants === 'string' ) return variants

        const 
            inum = parseInt( num ),
            rnum = inum % 100 < 20 ? inum % 100 : inum % 10

        if ( rnum === 0 ) return variants[3] ? variants[3] : variants[2]
        if ( rnum > 4 ) return variants[2]
        if ( rnum > 1 ) return variants[1]

        return variants[0]
    }

export const rounder = num =>
    {
        if ( num < 1000 ) return num
        if ( num < 10000 ) return Math.floor( num / 100 ) / 10 + ' тыс.'
        if ( num < 100000 ) return Math.floor( num / 1000 ) + ' тыс.'
        if ( num < 1000000 ) return Math.floor( num / 1000 ) + ' тыс.'
        if ( num < 10000000 ) return Math.floor( num / 100000 ) / 10 + ' млн.'
        return Math.floor( num / 1000000 ) + ' млн.'
    }
    
const ruEn = {
    'а': 'a',
    'б': 'b',
    'в': 'v',
    'г': 'g',
    'д': 'd',
    'е': 'e',
    'ё': 'yo',
    'ж': 'zh',
    'з': 'z',
    'и': 'i',
    'й': 'j',
    'к': 'k',
    'л': 'l',
    'м': 'm',
    'н': 'n',
    'о': 'o',
    'п': 'p',
    'р': 'r',
    'с': 's',
    'т': 't',
    'у': 'u',
    'ф': 'f',
    'х': 'h',
    'ц': 'ts',
    'ч': 'ch',
    'ш': 'sh',
    'щ': 'sch',
    'ъ': '',
    'ы': 'y',
    'ь': '',
    'э': 'e',
    'ю': 'yu',
    'я': 'ya'
}

export const makeSlug = str => 
    str.toLowerCase().split( '' ).map( l => ruEn[l] !== void 0 ? ruEn[l] : l ).join( '' ).replace( /\W/g, '-' )

export const toSimple = str => str ? str.toLowerCase().replace( /[^0-9a-zа-яё ]+/g, ' ' ).replace( / +/g, ' ' ).trim() : ''

export const capitalize = str => str[0].toUpperCase() + str.substr( 1 )

export const validateEmail = email => 
    {
        const 
            // eslint-disable-next-line
            re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        return re.test( String( email ).toLowerCase() )
    }