import * as strings from './strings'

export const unique = ( item, index, arr ) => ( arr.indexOf( item ) === index )

export const bySubstring = ( raw, keys ) => item => 
    {
        if ( !item ) return false
        
        const
            search = strings.toSimple( raw )
        
        if ( typeof item === 'object' && keys ) {
            return keys.map( key => strings.toSimple( item[key] ).search( search ) > -1 ).includes( true )
        }

        return strings.toSimple( item ).search( search ) > -1
    }

export const exclude = ( obj, keys ) =>
    {
        const
            res = {}

        Object.keys( obj ).forEach( key => {
            if ( !keys.includes( key ) ) {
                res[key] = obj[key]
            }
        })

        return res
    }

export const diff = ( arr, key ) => item => arr.indexOf( item[key] ) < 0
export const diffByKey = ( arr, key ) => item => !arr || !arr.find( i => i[key] === item[key] )