import { request } from 'tools'
import qs from 'qs'

export const auth = body =>
    request.post(
        '/auth/token',
        qs.stringify( body ),
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
    )