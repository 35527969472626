/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout, Button, Dropdown, Menu, Divider, Upload } from 'antd'
import Icon, { PlusOutlined, SettingOutlined, SaveOutlined, BuildOutlined, FolderOpenOutlined } from '@ant-design/icons'

/* APPLICATION */
import { Icons } from 'components'
import { generate, storage, t, i18next } from 'tools'
import config from 'config'

import './app-head.scss'

const
    { Header } = Layout

class AppHead extends Component {

    static propTypes = {
        settings: PropTypes.object,
        noTag:    PropTypes.string,
        update:   PropTypes.any,

        headRef:   PropTypes.object,

        onSettings: PropTypes.func,
        onUpdate:   PropTypes.func,
        onCreate:   PropTypes.func
    }

    list = React.createRef()

    constructor ( props ) {
        super( props )

        this.state = {
            modes: false,
            sizes: false,
            tags:  false,
            langs: false,

            overflow: false,
            cut: -1, 
            place: 'calc(100% -32px)',
            placing: false
        }

        this.set = generate.set( this )
    }

    componentDidUpdate () {
        const
            { overflow, placing, place } = this.state,
            list = this.list.current

        if ( !list ) return

        const
            ch = list.clientHeight,
            sh = list.scrollHeight

        if ( sh > ch ) {
            if ( !placing ) {
                const
                    btns = [].slice.call( document.querySelectorAll('.tags-selection .ant-btn') ),
                    right = btns.filter( node => node.offsetTop < 32 && node.className.indexOf( 'overflow' ) < 0 ).pop(),
                    offset = right
                        ? right.offsetLeft + right.clientWidth + 8
                        : (
                            btns.length > 0
                                ? 104
                                : place
                        )

                if ( offset === place ) return

                this.set.state({
                    placing: true,
                    cut: btns.indexOf( right ),
                    place: offset
                })

                setTimeout( () => this.set.placing( false ), 100 )
            }

            ;( !overflow ) && ( this.set.overflow( true ) )
        }

        ;( sh === ch && overflow ) && (
            this.set.state({
                overflow: false,
                cut: -1,
                place: 'calc(100% - 32px)'
            })
        )
    }

    count = tag =>
        {
            const
                { noTag } = this.props,
                events = storage.load( 'events' ).events

            if ( tag === noTag ) return events.filter( e => e.tags.length === 0 )
            return events.filter( e => e.tags.includes( tag ) )
        }
    
    tags = () =>
        {
            const
                { settings } = this.props,
                handler = tag => e => {
                    e.preventDefault()

                    const
                        tags = [ ...settings.filters ]

                    ;( tags.includes( tag ) )
                        ? tags.splice( tags.indexOf( tag ), 1 )
                        : tags.push( tag )

                    this.props.onUpdate( 'filters' )( tags )
                },
                tags = ([
                        this.props.noTag,
                        ...storage.extract( 'events', 'tags' )
                    ])
                    .sort(( a, b ) => this.count( b ).length - this.count( a ).length )

            if ( tags.length < 1 ) {
                return <Button key="none" disabled>{ t('tags.none') }</Button>
            }

            return tags.map( tag => (
                <Button 
                    key  = { tag } 
                    type = { settings.filters.includes( tag ) ? 'primary' : '' }

                    onClick = { handler( tag ) }
                >
                    { t(tag) }
                </Button>
            ))
        }

    toggleColorMode = e =>
        {
            e.preventDefault()

            const
                { settings } = this.props,
                current = config().ui.colorModesIndex.indexOf( settings.colorMode ),
                next = current === config().ui.colorModesIndex.length - 1 ? 0 : current + 1

            this.props.onUpdate( 'colorMode' )( config().ui.colorModesIndex[ next ] )
        }

    toggleTheme = e =>
        {
            e.preventDefault()

            const
                { settings } = this.props,
                theme = settings.theme === 'verve' ? 'antd' : 'verve'

            this.props.onUpdate( 'theme' )( theme )
        }

    setColorMode = value => () => this.props.onUpdate( 'colorMode' )( value )
    setTextSize = e => this.props.onUpdate( 'zoom', 'array', config().zoom )( config().zoom.indexOf( e.key ) )

    setLanguage = value => () => 
        {
            i18next.changeLanguage( value )
            this.props.onUpdate( 'language' )( value )
            this.forceUpdate()
        }

    colorMode = () =>
        {
            const
                { settings } = this.props,
                { modes } = this.state,
                mode = settings.colorMode,
                menu = (
                    <Menu>
                        {
                            Object.keys( config().ui.colorModes ).map( key => (
                                <Menu.Item 
                                    key       = { key}
                                    className = { `color-mode-item color-${key}${mode === key ? ' current' : ''}` }
                                    onClick   = { this.setColorMode( key ) }
                                >
                                    { config().ui.colorModes[key] }
                                </Menu.Item>
                            ))
                        }
                    </Menu>
                ),
                cls = [ 'color-mode', `mode-${mode}` ]

            return (
                <Dropdown
                    trigger = "click"
                    visible = { modes }
                    overlay = { menu }

                    onVisibleChange = { this.set.modes }
                >
                    <Icon 
                        title = { config().ui.colorModes[mode] }

                        className = { cls.join( ' ' ) }
                        style     = {{ marginRight: 8 }}
                        component = { Icons.colors }
                    />
                </Dropdown>
            )
        }

    languages = () =>
        {
            const
                { settings } = this.props,
                { langs } = this.state,
                lang = settings.language,
                menu = (
                    <Menu>
                        {
                            Object.keys( i18next.options.resources ).map( key => (
                                <Menu.Item 
                                    key       = { key}
                                    className = { `color-mode-item color-${key}${lang === key ? ' current' : ''}` }
                                    onClick   = { this.setLanguage( key ) }
                                >
                                    { i18next.options.resources[key].main.global.language }
                                </Menu.Item>
                            ))
                        }
                    </Menu>
                ),
                cls = [ 'language', `lang-${lang}` ]

            return (
                <Dropdown
                    trigger = "click"
                    visible = { langs }
                    overlay = { menu }

                    onVisibleChange = { this.set.langs }
                >
                    <Icon 
                        title = { i18next.options.resources[lang].main.global.language }

                        className = { cls.join( ' ' ) }
                        component = { Icons.Lang }
                    />
                </Dropdown>
            )
        }

    textSize = () =>
        {
            const
                { settings } = this.props,
                { sizes } = this.state,
                value = config().zoom.indexOf( settings.zoom.toString() ),
                menu = (
                    <Menu>
                        {
                            config().zoom.map( key => (
                                <Menu.Item 
                                    key       = { key }
                                    className = { `text-size-item${value === config().zoom.indexOf( key ) ? ' current' : ''}` }
                                    style     = {{ fontSize: `${key}em` }}

                                    onClick = { this.setTextSize }
                                >
                                    A
                                </Menu.Item>
                            ))
                        }
                    </Menu>
                )

            return (
                <Dropdown
                    trigger = "click"
                    visible = { sizes }
                    overlay = { menu }
                    onVisibleChange = { this.set.sizes }
                >
                    <span
                        className = "text-size" 
                        style     = {{ marginLeft: 'auto' }}
                    >
                        Aa
                    </span>
                </Dropdown>
            )
        }

    themes = () =>
        {
            const
                { settings } = this.props,
                { theme } = settings

            return (
                <Icon 
                    title = { config().ui.themesSelect[theme] }

                    className = "theme-selector"
                    component = { theme === 'verve' ? Icons.Light : Icons.Dark }

                    onClick = { this.toggleTheme }
                />
            )
        }

    overflow = () =>
        {
            const
                { settings, noTag } = this.props,
                { overflow, cut, place, tags } = this.state,
                handler = tag => e => {
                    ;( e.preventDefault ) && ( e.preventDefault() )

                    const
                        tags = [ ...settings.filters ]

                    ;( tags.includes( tag ) )
                        ? tags.splice( tags.indexOf( tag ), 1 )
                        : tags.push( tag )

                    this.props.onUpdate( 'filters' )( tags )
                },
                all = ([
                        noTag,
                        ...storage.extract( 'events', 'tags' )
                    ])
                    .sort(( a, b ) => this.count( b ).length - this.count( a ).length ),
                cutted = all.slice( cut + 1 ),
                menu = (
                    <Menu selectedKeys={settings.filters}>
                        {
                            cutted.map( key => (
                                <Menu.Item 
                                    key       = { key }
                                    className = "tag-item"
                                    onClick = { handler( key ) }
                                >
                                    { key }
                                </Menu.Item>
                            ))
                        }
                    </Menu>
                )
            
            return (
                <Dropdown
                    trigger = { cut > -1 ? 'click' : 'none' }
                    visible = { tags && cut > -1 }
                    overlay = { menu }

                    overlayStyle    = { cut > -1 ? {} : { display: 'none' } }
                    onVisibleChange = { this.set.tags }
                >
                    <Button className={`overflow-button ${overflow ? 'visible' : ''}`} style={{ left: place }}>...</Button>
                </Dropdown>
            )
        }

    render () {
        const
            { headRef, settings } = this.props

        return (
            <Header ref={headRef} className="app-head">
                <div className="tags-selection" ref={this.list}>
                    <label>{ t('tags.title') }: </label>
                    <span 
                        className = { `tags-clear ${settings.filters.length < 1 ? 'invis' : ''}` }
                        onClick   = { () => this.props.onUpdate( 'filters' )([]) }
                    >
                        ×
                    </span>
                    { this.tags() }
                    { this.overflow() }
                </div>
                
                <Upload
                    accept = ".json"
                    showUploadList = { false }
                    customRequest  = { this.props.onOpen }
                >
                    <Button
                        ghost
                        type  = "secondary"
                        icon  = { <FolderOpenOutlined /> }
                    />
                </Upload>
                <Button
                    ghost
                    type  = "secondary"
                    icon  = { <SaveOutlined /> }

                    onClick = { this.props.onSave }
                />

                <Divider type="vertical" />

                <Button
                    ghost
                    type  = "secondary"
                    icon  = { <BuildOutlined /> }

                    onClick = { this.props.onRearrange }
                />

                <Divider type="vertical" />

                { settings.showInHead.includes( 'zoom' ) && this.textSize() }
                { settings.showInHead.includes( 'colorMode' ) && this.colorMode() }
                { settings.showInHead.includes( 'theme' ) && this.themes() }
                { settings.showInHead.includes( 'language' ) && this.languages() }

                <Button
                    ghost
                    type  = "secondary"
                    style = {{ marginRight: 16 }}
                    icon  = { <SettingOutlined /> }

                    onClick = { this.props.onSettings }
                />
                <Button
                    type  = "primary"
                    icon  = { <PlusOutlined /> }

                    onClick = { this.props.onCreate }
                />
            </Header>
        )
    }
}

export default AppHead