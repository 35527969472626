/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

/* APPLICATION */
import { t } from 'tools'
import './matrix-section.scss'

class MatrixSection extends Component {

    static propTypes = {
        setRef: PropTypes.any,
        settings: PropTypes.object,

        urgent:    PropTypes.bool,
        important: PropTypes.bool,

        onAdd:  PropTypes.func
    }

    keys = {
        urgent: state => this.props.settings ? t( this.props.settings.keys[~~state] ) : '',
        important: state => this.props.settings ? t( this.props.settings.keys[~~state + 2] ) : ''
    }

    render () {
        let
            { urgent, important } = this.props,
            cls = 'ematrix-section '

        cls += urgent ? 'urgent' : 'nonurgent'
        cls += ' '
        cls += important ? 'important' : 'unimportant'
        
        return (
            <section
                className = { cls }
                ref = { this.props.setRef }

                onDoubleClick = { this.props.onAdd }
            >
                <label className="v-label">{ this.keys.important( important ) }</label>
                <label className="h-label">{ this.keys.urgent( urgent ) }</label>
            </section>
        )
    }
}

export default MatrixSection
