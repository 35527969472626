/* VENDOR */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import Linkify from 'react-linkify'
import { Popover } from 'antd'

/* APPLICATION */
import { generate, t } from 'tools'
import config from 'config'
import './event.scss'

class Event extends Component {

    static propTypes = {
        colorMode: PropTypes.oneOf( config().ui.colorModesIndex ),
        theme:     PropTypes.string,
        
        data: PropTypes.object,
        position: PropTypes.object,

        onStart: PropTypes.func,
        onDrag:  PropTypes.func,
        onStop:  PropTypes.func,
        onEdit:  PropTypes.func,
        onTag:   PropTypes.func
    }

    constructor ( props ) {
        super( props )

        this.state = {
            dragging: false 
        }

        this.set = generate.set( this )
    }

    toHex = color => `#${color.r.toString(16)}${color.g.toString(16)}${color.b.toString(16)}`

    adaptive = data =>
        {
            const
                { colorMode, theme } = this.props,
                { dragging } = this.state,
                res = {}

            if ( colorMode !== 'adaptive' || dragging ) return res

            return {
                backgroundColor: this.toHex( window.themes[theme].getBGColor( data ) ),
                color: this.toHex( window.themes[theme].getColor( data ) )
            }
        }

    cls = data =>
        {
            const
                { colorMode } = this.props,
                res = [ 'ematrix-event' ]

            if ( colorMode === 'none' ) {
                res.push( 'no-color' )
                return res.join( ' ' )
            }

            res.push( `color-${colorMode}` )

            ;( data.urgency >= 0 ) && ( res.push( 'urgent' ) )
            ;( data.importance >= 0 ) && ( res.push( 'important' ) )

            return res.join( ' ' )
        }

    edit = key => e =>
        {
            if ( e ) {
                ;( e.persist ) && ( e.persist() )
                e.stopPropagation()
                e.preventDefault()
            }

            setTimeout( () => {
                this.props.onEdit( e, key || 'title' )
                this.set.dragging( true )
                setTimeout( () => this.set.dragging( false ), 0 )
            }, 0 )
        }

    prevent = e => 
        {
            e.stopPropagation()
        }

    link = ( href, text, key ) => 
        (
            //eslint-disable-next-line
            <a target="_blank" href={href} key={key} onMouseDown={this.prevent}>
                { text }
            </a>
        )

    description = data =>
        {
            if ( !data.content ) {
                return (
                    <p><i>
                        <span className="link" onMouseDown={this.edit( 'text' )}>
                            { t('event.addDescription') }
                        </span>
                    </i></p>
                )
            }
            
            return (
                <Linkify componentDecorator={ this.link }>
                    {
                        data.content
                            .split( '\n' )
                            .map( p => (
                                <p key={p}>{ p }</p>
                            ))
                    }
                </Linkify>
            )
        }

    tags = data =>
        {
            if ( data.tags.length < 1 ) {
                return (
                    <p><i>
                        <span className="link" onMouseDown={this.edit( 'tags' )}>
                            { t('event.addTags') }
                        </span>
                    </i></p>
                )
            }
            
            return (
                <footer className="event-tags">
                    { data.tags.map( tag => <span key={tag} onMouseDown={this.props.onTag(tag)}>{ tag }</span> ) }
                </footer>
            )
        }

    content = data =>
        {
            if ( !data.content && data.tags.length < 1 ) {
                return (
                    <p><i>
                        <span className="link" onMouseDown={this.edit( 'text' )}>
                            { t('event.addTagsAndDesc') }
                        </span>
                    </i></p>
                )
            }

            const
                content = this.description( data ),
                tags = this.tags( data )

            return (
                <React.Fragment>
                    { content }
                    { tags }
                </React.Fragment>
            )
        }

    start = ( e, data ) =>
        {
            this.set.dragging( true )
            this.props.onStart( e, data )
        }

    stop = ( e, data ) =>
        {
            this.set.dragging( false )
            this.props.onStop( e, data )
        }

    dragLock = () =>
        {
            const
                { dragging } = this.state

            if ( dragging ) return { visible: false }
            return {}
        }

    render () {
        const
            { data, position } = this.props

        return (
            <Draggable
                position = { position }
                bounds   = 'parent'

                onStart = { this.start }
                onDrag  = { this.props.onDrag }
                onStop  = { this.stop }
            >
                <div className={this.cls( data )} id={data.id}>
                    <Popover
                        { ...this.dragLock() }
                        trigger = "hover"

                        mouseEnterDelay = { .5 }
                        mouseLeaveDelay = { .1 }
                        
                        content = { this.content( data ) }
                    >
                        <div className="ematrix-event-content" onDoubleClick={this.props.onEdit} style={this.adaptive(data)}>
                            { data.title }
                        </div>
                    </Popover>
                </div>
            </Draggable>
        )
    }
}

export default Event
