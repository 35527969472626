export const memkey = '_eis_storage_'

export const save = ( key, data, cb ) =>
    {
        localStorage.setItem( `${memkey}${key}`, JSON.stringify( data ) )
        ;( cb ) && ( cb() )
    }

export const load = key => JSON.parse( localStorage.getItem( `${memkey}${key}` ) )

export const events = 
    {
        save: ( key, events, cb ) => save( key, { version: 1, events }, cb ),
        load: key => 
            {
                const
                    raw = JSON.parse( localStorage.getItem( `${memkey}${key}` ) )

                if ( Array.isArray( raw ) || !raw ) {
                    // V 0
                    return raw
                }

                switch ( raw.version ) {
                    case 1:
                    default:
                        return raw.events
                }

            }
    }

export const extract = ( dataKey, attKey, rawSorter ) =>
    {
        const
            data = events.load( dataKey ),
            sorter = rawSorter || ( ( a, b ) => a.localeCompare( b ) ),
            res = []

        if ( !data ) return []

        data.forEach( item => item[attKey].forEach( value => res.push( value ) ) )

        return res.filter( ( item, index ) => res.indexOf( item ) === index ).sort( sorter )
    }