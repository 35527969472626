/* VENDOR */
import React, { Component } from 'react'
import { Layout, Modal, Checkbox, Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import moment from 'moment'

/* APPLICATION */
import { Center, Event, EditEvent, Settings, MatrixSection, AppHead } from 'components'
import { generate, geom, storage, copy, filter, t } from 'tools'
import config from 'config'

import Get from './get'
import connector from './connector'
import './matrix.scss'

const
    { Content } = Layout,
    rawSet = storage.load( 'settings' ),
    settings = rawSet
        ? { ...config().def.settings, ...rawSet }
        : { ...config().def.settings },
    saved = storage.events.load( 'events' ) || config().def.events,
    noTags = 'uncategorized'

class Matrix extends Component {

    container = React.createRef()
    header = React.createRef()
    trash = React.createRef()

    //U - urgent
    //I - important
    //N - nonurgent
    //M - unimportant

    UI = React.createRef()
    NI = React.createRef()
    UM = React.createRef()
    NM = React.createRef()

    constructor ( props ) {
        super( props )

        this.state = {
            loaded: false,
            headFlush: generate.uniq(),

            settings: { ...settings },
            showSettings: false,
            removeImmediately: settings.removeImmediately,

            events: [...saved],
            off: { x: 0, y: 0 },
            
            remove: false,
            toRemove: null,

            edit: false,
            toEdit: null,
            editFocus: 'title',

            openError: false,
            errorFile: '',
            errorMessage: '',
            toOpen: null
        }

        this.set = generate.set( this )
        this.get = new Get( this )
    }

    componentDidMount () {
        this.updateTheme()
        this.redraw()
        window.addEventListener( 'resize', this.redraw )
        this.set.loaded( true )
        setTimeout( () => {
            const
                ev = document.createEvent( 'HTMLEvents' )
            ev.initEvent( 'resize', true, false )
            window.dispatchEvent( ev )
        }, 0 )
    }

    componentWillUnmount () {
        window.removeEventListener( 'resize', this.redraw )
    }

    componentDidUpdate ( prevProps, prevState ) {
        const
            { settings, events } = this.state

        if ( settings !== prevState.settings ) {
            storage.save( 'settings', settings )
            ;( document.activeElement.className.indexOf( 'ant-select-selection-search-input' ) > -1 ) && 
                ( document.querySelector( 'button' ).focus() )
        }

        ;( events !== prevState.events ) && ( 
            storage.events.save( 
                'events' , 
                events.map( event => {
                    event.tags = event.tags.map( tag => tag.toLowerCase() )
                    return event
                }),
                () => this.set.headFlush( generate.uniq() )
            )
        )

        ;( settings.theme !== prevState.settings.theme ) && ( this.updateTheme() )
    }

    redraw = () => this.forceUpdate()

    restoreAndClose = () =>
        {
            this.settings( 'removeImmediately' )( this.state.removeImmediately )
            this.close()
        }

    close = () =>
        {
            this.set.state({
                edit: this.state.edit && this.state.remove ? true : false,
                toEdit: this.state.edit && this.state.remove ? this.state.toEdit : null,
                remove: false,
                showSettings: false,
                openError: false,
                toOpen: null
            })

            setTimeout( () => {
                this.set.state({
                    errorFile: '',
                    errorMessage: '',
                })
            }, 300 )
        }

    openSettings = () => this.set.showSettings( true )

    updateTheme = () =>
        {
            const
                { settings } = this.state

            document.documentElement.className = 'theme-changing'

            document.body.className = document.body.className.replace( /theme-[^ ]+/g, '' )
            document.body.classList.add( `theme-${settings.theme}` )

            setTimeout( () => document.documentElement.className = '', 0 )
        }

    askRemove = () => 
        {
            const
                { settings, toEdit } = this.state

            if ( settings.removeImmediately ) {
                this.set.state(
                    {
                        toRemove: toEdit
                    },
                    this.remove
                )
            } else {
                this.set.state({
                    toRemove: toEdit,
                    remove: true,
                    removeImmediately: settings.removeImmediately
                })
            }
        }

    remove = () =>
        {
            const
                { toRemove } = this.state,
                events = copy.array( this.state.events ),
                found = events.find( ev => ev.id === toRemove.id ),
                index = events.indexOf( found )

            if ( index > -1 ) {
                events.splice( index, 1 )
            }

            this.set.state({
                edit: false,
                toEdit: null,
                remove: false,
                toRemove: null,
                events
            })
        }

    create = () => this.add()

    add = e =>
        this.set.state({
            edit: true,
            editFocus: 'title',
            toEdit: { 
                ...config().def.draft,
                id: generate.uniq(),
                ...this.get.place( e )
            }
        })

    edit = data => ( e, focus ) =>
        {
            e.preventDefault()
            e.stopPropagation()

            this.set.state({
                edit: true,
                toEdit: data,
                editFocus: focus || 'title'
            })
        }

    append = () =>
        {
            const
                { toOpen, events } = this.state,
                merge = [ ...toOpen, ...events ].filter( ( e, i, a ) => a.findIndex( ev => ev.id === e.id ) === i )

            this.set.events( merge )
            this.close()
        }

    replace = () =>
        {
            const
                { toOpen } = this.state

            this.set.events( toOpen )
            this.close()
        }

    open = req =>
        {
            const
                { file } = req,
                reader = new FileReader()

            reader.readAsText( file )
          
            reader.onload = () => {
                const
                    raw = reader.result,
                    errors = {
                        fileType: e => {
                            this.set.state({
                                openError: true,
                                errorMessage: 'Wrong file format',
                                errorFile: req.file.name
                            })
                            reader.abort()
                        }
                    }

                let
                    data = null
                    
                try {
                    data = JSON.parse( raw )
                } catch ( e ) {
                    errors.fileType( e )
                    return
                }

                if ( !data.version ) {
                    errors.fileType( 'No version' )
                    return
                }

                switch ( data.version ) {
                    case 1:
                    default:
                        if ( !data.events ) {
                            errors.fileType( 'No events' )
                            return
                        }

                        this.set.toOpen( data.events )
                }
            }
          
            reader.onerror = () => {
                this.set.state({
                    openError: true,
                    errorMessage: 'Error opening file',
                    errorFile: req.file.name
                })
            }
        }

    save = () =>
        {
            const
                raw = JSON.stringify( storage.load( 'events' ) ),
                data = this.base64( raw ),
                a = document.createElement( 'a' )

            a.href = `data:application/jsoncharset=utf-8;base64,${data}`
            a.setAttribute( 'download', `Eisenatrix Export ${moment().format('YYYY-MM-DD HHmmss')}.json` )

            a.click()
        }

    base64 = raw =>
        (
            btoa(
                encodeURIComponent( raw ).replace(
                    /%([0-9A-F]{2})/g,
                    ( match, p1 ) => String.fromCharCode('0x' + p1)
                )
            )
        )

    dragUpdates = data => e =>
        {
            const
                { toRemove, settings } = this.state,
                { current } = this.trash,
                { urgency, importance } = this.get.place( e )

            if ( current ) {
                const
                    box = current.getBoundingClientRect()

                if ( geom.contains( box, e ) ) {
                    this.set.toRemove( data )
                } else if ( !!toRemove ) {
                    this.set.toRemove( null )
                }
            }
                        
            document.body.classList.remove( 'in-urgent' )
            document.body.classList.remove( 'in-nonurgent' )
            document.body.classList.remove( 'in-important' )
            document.body.classList.remove( 'in-unimportant' )

            if ( settings.colorMode !== 'none' ) {
                const
                    ucls = ( urgency >= 0 )
                        ? 'in-urgent'
                        : 'in-nonurgent',
                    icls = ( importance >= 0 )
                        ? 'in-important'
                        : 'in-unimportant'

                document.body.classList.add( ucls )
                document.body.classList.add( icls )
            }
        }

    catchOffset = data => e =>
        {
            const
                { o } = this.get.offsets(),
                pos = this.get.position( data ),
                x = e.pageX - pos.x,
                y = e.pageY - pos.y - o

            this.set.off({ x, y })
            this.dragUpdates( data )( e )

            return true
        }

    settings = generate.update( this, 'settings' )

    resetKeys = () =>
        this.set.settings({
            ...this.state.settings,
            keys: config().def.settings.keys
        })

    updateEvent = data =>
        {
            const
                events = copy.array( this.state.events ),
                found = events.find( ev => ev.id === data.id ),
                index = events.indexOf( found )

            if ( index > -1 ) {
                events[index] = data
            } else if ( data._new ) {
                delete data._new
                events.push( data )
            }

            this.set.events( events )
            this.close()
        }

    update = data => ( keys, value ) =>
        {
            const
                events = copy.array( this.state.events ),
                found = events.find( ev => ev.id === data.node.id ),
                index = events.indexOf( found )

            if ( typeof keys === 'object' ) {
                Object.keys( keys ).forEach( key => {
                    found[key] = keys[key]
                })
            } else {
                found[keys] = value
            }

            events[index] = found
            this.set.events( events )
        }

    rearrange = () =>
        {
            const
                events = copy.array( this.state.events )

            this.set.events( events.map( e => this.get.fitToGrid( e ) ) )
        }

    move = ( e, data ) =>
        {
            e.stopPropagation()
            e.preventDefault()

            const
                { toRemove, settings } = this.state,
                { urgency, importance } = this.get.place( e )

            this.update( data )({ urgency, importance })

            if ( !!toRemove ) {
                ;( settings.removeImmediately )
                    ? setTimeout( this.remove, 100 )
                    : this.set.state({
                        remove: true,
                        removeImmediately: settings.removeImmediately
                    })
            }
        }

    filter = tag => e =>
        {
            const
                { settings } = this.state

            e.stopPropagation()
            e.preventDefault()
            
            this.settings( 'filters' )( [ ...settings.filters, tag ].filter( filter.unique ) )
        }

    filtered = item =>
        {
            const
                { settings } = this.state

            if ( settings.filters.length < 1 ) return true

            return settings.filters.map( f => item.tags.includes( f ) || ( f === noTags && item.tags.length === 0 ) ).includes( true )
        }

    sections = () => [ 2, 3, 0, 1 ].map( n => this.section( !!( n & 1 ), !!( n & 2 ) ) )

    zoom = () =>
        ({
            fontSize: Math.round( 12 * this.state.settings.zoom )
        })

    event = data =>
        (
            <Event
                key = { data.id }
                
                data      = { data }
                position  = { this.get.position( data ) }
                colorMode = { this.state.settings.colorMode }
                theme     = { this.state.settings.theme }

                onStart = { this.catchOffset( data ) }
                onDrag  = { this.dragUpdates( data ) }
                onStop  = { this.move }
                onEdit  = { this.edit(data) }
                onTag   = { this.filter }
            />
        )

    section = ( urgent, important ) =>
        (
            <MatrixSection
                key = { `${urgent}${important}` }
                setRef = { this.get.matrix()[~~urgent][~~important] }

                settings = { this.state.settings }

                urgent    = { urgent }
                important = { important }

                onAdd = { this.add }
            />
        )

    render () {
        const
            { events, edit, toEdit, editFocus } = this.state,
            { remove, toRemove } = this.state,
            { openError, errorFile, errorMessage, toOpen } = this.state,
            { settings, showSettings, loaded, headFlush } = this.state,
            trashCls = [ 'trash-bin' ]

        ;( !!toRemove ) && ( trashCls.push( 'drag-over' ) )

        if ( !loaded ) return null

        return (
            <section className="app-page app-page-matrix">
                <Layout>
                    <AppHead
                        headRef = { this.head }
                        update  = { headFlush }

                        settings = { settings }
                        noTag    = { noTags }
                        style    = { this.zoom() }

                        onCreate    = { this.create }
                        onUpdate    = { this.settings }
                        onRearrange = { this.rearrange } 
                        onSettings  = { this.openSettings }
                        onSave      = { this.save }
                        onOpen      = { this.open }
                    />
                    <Content>

                        <div className="ematrix" ref={this.container}>
                            { this.sections() }
                        </div>

                        <div className="events-container" style={ this.zoom() }>
                            { events.filter( this.filtered ).map( this.event ) }
                        </div>

                        <div className={trashCls.join( ' ' )} ref={this.trash}>
                            <Center>
                                <DeleteOutlined />
                            </Center>
                        </div>
                    </Content>
                </Layout>

                <EditEvent
                    visible = { edit }
                    data    = { toEdit }
                    focus   = { editFocus }

                    onSave   = { this.updateEvent }
                    onClose  = { this.close }
                    onRemove = { this.askRemove }
                />

                <Modal
                    title = { t('files.askOverwrite') }
                    visible = { toOpen }
                    className = "no-footer"

                    okButtonProps = {{ style: { display: 'none' }}}
                    cancelButtonProps = {{ style: { display: 'none' }}}

                    onCancel = { this.close }
                >
                    { t( `files.askOverwriteDesc` ) }

                    <footer className="add-footer">
                        <Button
                            ghost
                            type = "secondary"
                            onClick = { this.close }
                        >
                            { t('global.cancel') }
                        </Button>

                        <Button
                            danger
                            type = "primary"
                            onClick = { this.replace }
                        >
                            { t('global.replace') }
                        </Button>

                        <Button
                            type = "primary"
                            onClick = { this.append }
                        >
                            { t('global.append') }
                        </Button>
                    </footer>
                </Modal>

                <Modal
                    title = { t('files.openErrorTitle') }
                    visible = { openError }

                    okText = { t('global.close') }
                    onOk = { this.close }

                    cancelButtonProps = {{ style: { display: 'none' } }}
                >
                    { t( `files.${errorMessage}`, { fileName: errorFile }) }
                </Modal>

                <Modal
                    title = { t('event.remove') }
                    visible = { remove }

                    okText = { t('global.remove') }
                    okButtonProps = {{ danger: true }}
                    onOk = { this.remove }

                    cancelText = { t('global.cancel') }
                    onCancel = { this.restoreAndClose }
                >
                    { t('event.askRemove') }
                    <div className="modal-off-footer">
                        <Checkbox 
                            checked = { settings.removeImmediately } 
                            onChange = { this.settings( 'removeImmediately', 'checked' ) }
                        >
                            { t('global.neverAsk') }
                        </Checkbox>
                        <br/>
                        <small>{ t('global.changeInSettings') }</small>
                    </div>
                </Modal>

                <Settings
                    visible  = { showSettings }
                    settings = { settings }

                    onChange = { this.settings }
                    onClose  = { this.close }
                    onFlush  = { this.resetKeys }
                />
            </section>
        )
    }
}

export default connector( Matrix )