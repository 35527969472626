import { update } from 'reduxigen'
import { request } from 'tools'

export const flushNetworkError = request.updateAndSave(
    'storage.ui.networkerror.flush',
    ( n, state ) => ({
        ...state,
        networkError: false
    })
)

export const addNotification = update(
    'storage.ui.notifications.add',
    ( data, state ) => ({
        ...state,
        ui: {
            ...state.ui,
            notifications: (
                state.ui.notifications
                    ? [
                        ...state.ui.notifications,
                        data
                    ]
                    : [ data ]
            )
        }
    })
)