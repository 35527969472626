import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import ru from "i18n/ru.json"
import en from "i18n/en.json"
import es from "i18n/es.json"
import jp from "i18n/jp.json"

i18next.use(LanguageDetector).init({
    interpolation: { escapeValue: false },
    defaultNS: 'main',
    resources: {
        en: {
            main: en
        },
        ru: {
            main: ru
        },
        es: {
            main: es
        },
        jp: {
            main: jp
        }
    },
})

const t = ( a, b, c ) => i18next.t( a, b, c )

export { i18next }
export default t