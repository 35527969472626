/* VENDOR */
import React, { Component } from 'react'

/* APPLICATION */
import './center.scss'

class Center extends Component {
    cls = () =>
        (
            this.props.className
                ? 'center-container ' + this.props.className
                : 'center-container'
        )

    render () {
        return (
            <div className={this.cls()}>
                {this.props.children}
            </div>
        )
    }
}

export default Center
