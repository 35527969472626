import './verve.scss'

window.themes = window.themes || {}

window.themes.verve = {
    getBGColor: data =>
        {
            const
                // def color
                br = 43,
                bg = 42,
                bb = 46,
                u = data.urgency,
                i = data.importance,
                r = Math.round( br + ( 134 - br ) * Math.max( 0, u ) + ( 83 - br ) * ( ( u + 1 ) / 2 ) - ( br - 28 ) * Math.max( 0,  i ) * -Math.min( 0, u ) + ( 68 - br ) * Math.max( 0, u ) * -Math.min( 0, i ) ),
                g = Math.round( bg + (123 - bg ) * ( ( i + 1 ) / 2 ) * -( ( u - 1 ) / 2 ) + ( 135 - bg ) * Math.max( 0, ( ( u+.5 ) / 2 ) ) * -( ( i - 1 ) / 2 ) + 9 * Math.max( 0, u ) * Math.max( 0, i ) ),
                b = Math.round( bb + ( 83 - bb ) * ( ( i + 1 ) / 2 ) - 25 * ( ( u + 1 ) / 2 ) + 50 * ( ( u + 1 ) / 2 ) * -( Math.min( 0, i ) ) - 50 * Math.max( 0, i ) * ( 1 - Math.abs( u ) ) )

            return { r, g, b }
        },
    getColor: () => ({ r: 248, g: 248, b: 247})
}